import React from 'react';
import TimelineItem from './TimelineItem';
import TimelineData from './TimelineData'
import './Timeline.css';
import './PageContentWrapper.css';

const Timeline = () => TimelineData.length > 0 && (
  <div className="page-content-wrapper">
    <div className="timeline-container">
      {TimelineData.map((data, idx) => (
        <TimelineItem data={data} key={idx} />
      ))}
    </div>
  </div>
);

export default Timeline;

