import React from 'react';
import Activites from './Activites';
import ActivitesData from './ActivitesData'
import './About.css';
import './PageContentWrapper.css';

const About = () => (
  <div className="page-content-wrapper">
    {ActivitesData.length > 0 && (
      <div>
        {ActivitesData.map((data, idx) => (
          <Activites id={idx} data={data} key={idx} />
        ))}
      </div>
    )}
  </div>
);

export default About;