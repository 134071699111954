import React from 'react';
import Logo from './Logo';
import './Header.css';

function Header() {

  return (
    <header className="App-header">
      <Logo />
      <h1>👋 Hello, from your fav tech evangelist...</h1>
      <h6>Transforming 💡 ideas into reality, one code line at a time...</h6>
    </header>
  );
}

export default Header;

