import React from 'react';


function Footer() {
    return (
        <footer>
        <small>@2023 ElixirOwn development. All rights reserved </small>
      </footer>
    );

}

export default Footer;