import React, { useState, useEffect, useRef } from 'react';
import anime from 'animejs';

function Activites({ id, data }) {
  const [activityIndex, setActivityIndex] = useState(0);
  const [letters, setLetters] = useState([]);
  const timeline = useRef(null);

  const handleClick = () => {
    setActivityIndex((activityIndex + 1) % data.activity.length);
  };

  useEffect(() => {
    setLetters(data.activity[activityIndex].split(''));

    // Remove previous animation
    if (timeline.current) {
      timeline.current.pause();
      timeline.current = null;
    }

    // Use timeout to wait for state update before creating animation
    setTimeout(() => {
      // Create new timeline
      timeline.current = anime.timeline({ autoplay: true, loop: false });

      timeline.current.add({
        targets: `.letter-${id}`,
        opacity: [0, 1],
        translateY: [-600, 0], 
        easing: "easeOutExpo",
        duration: 1700,
        delay: (el, i) => 80 * (i + 1)
      });
    }, 0);

  }, [activityIndex, data.activity, id]);

  return (
    <div className="activity-div" style={{ color: data.color }}>
      <h1>
      {data.introText} <button className="activity-button" onClick={handleClick}>press me</button> {letters.map((letter, index) => <span className={`letter-${id}`} key={`${activityIndex}-${index}`}>{letter}</span>)}
      </h1>
    </div>
  );
}

export default Activites;







