

const data = [
    {
        text: 'roadmappa.xyz - Properly estimate your delivery dates',
        date: '01-06-2023',
        category : {
            tag: 'productivity',
            color: '#e17b77'
        },
        link: {
            url: 'https://en.wikipedia.org/wiki/New_Year\'s_Day',
            text: 'Read more'
        }
    },
    {
        text: 'Neucl.io - Eucliedean Rhythm Generator',
        date: '01-05-2023',
        category : {
            tag: 'medium',
            color: '#018f69'
        },
        link: {
            url: 'https://bit.ly/4dnJgwu',
            text: 'Read more'
        }
    }
]

export default data;
