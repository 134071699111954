// Navbar.js
import React from 'react';
import { Link } from 'react-scroll';
import './Navbar.css';

function Navbar() {
  return (
    <nav className="navbar">
      <Link to="home" smooth={true} duration={500} className="nav-link">Home</Link>
      <Link to="about" smooth={true} duration={500} className="nav-link">About</Link>
      <Link to="projects" smooth={true} duration={500} className="nav-link">Projects</Link>
      <Link to="contact" smooth={true} duration={500} className="nav-link">Contact</Link>
    </nav>
  );
};

export default Navbar;
