const dataActivites = [
    {
      introText: 'I am a',
      activity: ['full-stack engineer', 'data engineer', 'chatgpt whisperer', 'devops engineer', 'software developer', 'cloud engineer', 'lead engineer'],
      color: '#f00689'
    },
    {
      introText: 'I code in',
      activity: ['python', 'c#', 'javascript', 'typescript', 'r', 'bash', 'go', 'rust'],
      color: '#0689f0'
    },
    {
      introText: 'My tech stack',
      activity: ['react', 'fastapi', 'flask', 'docker', 'terraform', 'github actions', 'redis', 'sql dbs', 'dynamo db', 'databricks', 'graphql'],
      color: '#06f0e2'
    },
    {
      introText: 'I build on',
      activity: ['azure', 'gcp', 'digital ocean', 'premis'],
      color: '#06f06d'
    },
    {
      introText: 'I am interested in',
      activity: ['AI', 'crypto', 'vectorized dbs', 'machine learning', 'deep learning', 'LLMs', 'langchain', 'computer vision'],
      color: '#f0e206'
    }
    // Add as many entries as you need
  ];
  
  export default dataActivites;
  