
import './App.css';
import Header from './Header';
import { Element } from 'react-scroll';

import Footer from './Footer';
import Navbar from './Navbar';
import Timeline from './Timeline'
import About from './About'

function App() {
  return (
    <div className="App">
      <Navbar />
      <Element name="home" className="element">
        <Header />
      </Element>
      <Element name="about" className="element">
        <About />
      </Element>
      <Element name="projects" className="element">
        <Timeline />
      </Element>
      <Footer />
    </div>
  );
}

export default App;
